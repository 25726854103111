import type { SidebarNavLinkProps } from '../components/SidebarNav/Link';

import React, { useContext } from 'react';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useDashLinkUrl from '@core/hooks/useDashLinkUrl';
import { useSuperHubStore } from '@core/store';

import useIsWebhookSetupComplete from '@routes/MyDevelopers/Setup/PersonalizedDocs/useIsWebhookSetupComplete';
import BasePanel from '@routes/SuperHub/components/BasePanel';
import { SuperHubHashRoutePaths, type SuperHubHashRouteParams } from '@routes/SuperHub/types';

import { SidebarNav, SidebarNavSection, SidebarNavLink } from '../components';
import CustomComponents from '../forms/CustomComponents';
import PersonalizedDocs from '../forms/PersonalizedDocs';
import PersonalizedDocsSetup from '../forms/PersonalizedDocs/Setup';
import ProjectSettingsForm from '../forms/Project';
import Glossary from '../forms/Project/Glossary';
import ReusableContent from '../forms/ReusableContent';

import styles from './index.module.scss';

function Content() {
  const bem = useClassy(styles, 'Content');

  const { pathname } = useLocation();
  const isSingleProjectEnterpriseChild = useSuperHubStore(s => s.enterprise?.isChild && s.enterprise?.isSingleProject);

  const isSetupComplete = useIsWebhookSetupComplete();
  const match = matchPath<SuperHubHashRouteParams>(pathname, Object.values(SuperHubHashRoutePaths));
  const action = match?.params.action;

  const { project } = useContext(ProjectContext);
  const hasGraphQL = project?.flags.graphql;

  const dashLinkUrl = useDashLinkUrl();
  const contentNavData = [
    {
      icon: 'recycle',
      label: 'Reusable Content',
      to: '/content/reusable-content',
      planFeature: 'reusableContent',
    },
    {
      icon: 'component',
      label: 'Custom Components',
      to: '/content/custom-components',
    },
    {
      icon: 'bookmark',
      label: 'Glossary',
      to: '/content/glossary',
      planFeature: 'glossary',
    },
    {
      icon: 'key',
      label: 'Personalized Docs',
      to: isSetupComplete ? '/content/personalized-docs' : '/content/personalized-docs/setup',
    },
    {
      icon: 'home',
      label: 'Landing Page',
      href: isSingleProjectEnterpriseChild
        ? dashLinkUrl('global-landing-page', { toEnterpriseGroup: true })
        : dashLinkUrl('landing'),
      target: '_blank',
      planFeature: 'landing_page',
    },
    ...(hasGraphQL
      ? [
          {
            icon: 'graphql',
            label: 'GraphQL',
            href: dashLinkUrl('graphql'),
            target: '_blank',
          } satisfies SidebarNavLinkProps,
        ]
      : []),
  ] satisfies SidebarNavLinkProps[];

  return (
    <BasePanel label="Content settings controls" safeStyles={false}>
      <div className={bem('-layout')}>
        <SidebarNav className={bem('-sidebar')}>
          {action === 'content' && (
            <SidebarNavSection>
              {contentNavData.map((props: SidebarNavLinkProps) => (
                <SidebarNavLink key={`content-${props.label}`} {...props} />
              ))}
            </SidebarNavSection>
          )}
        </SidebarNav>

        {/* Routes to render sub-forms, grouped by sections in alphabetical order for readability */}
        <Switch>
          {/* Content routes */}
          <Route path="/:action(content)/:section(reusable-content)">
            <ReusableContent />
          </Route>
          <Route path="/:action(content)/:section(custom-components)">
            <CustomComponents />
          </Route>
          <Route path="/:action(content)/:section(glossary)">
            <ProjectSettingsForm>
              <Glossary />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(content)/:section(personalized-docs)/:type(setup)">
            <ProjectSettingsForm>
              <PersonalizedDocsSetup />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(content)/:section(personalized-docs)">
            <ProjectSettingsForm>
              <PersonalizedDocs />
            </ProjectSettingsForm>
          </Route>
          <Route exact path="/:action(content)">
            <Redirect to="/content/reusable-content" />
          </Route>
        </Switch>
      </div>
    </BasePanel>
  );
}

export default Content;
